<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="citizenDialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <vue-fontawesome :icon="['fas', 'user-plus']" class="mr-1" />Add
              Citizen
            </v-btn>
          </template>
          <v-card>
            <v-toolbar elevation="0">
              <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeCitizenDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid">
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="citizen.firstName"
                        required
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        hide-details="auto"
                      ></v-text-field>
                      <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="citizen.middleName"
                        id="middleName"
                        name="middleName"
                        label="Middle Name"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="citizen.lastName"
                        :rules="rules"
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="citizen.sid"
                        id="sid"
                        name="sid"
                        label="SID Number"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="citizen.gdc"
                        :rules="rules"
                        id="gdc"
                        name="gdc"
                        label="GDC Number"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="citizen.gender"
                        :items="genderOptions"
                        label="Gender"
                        hide-details="auto"
                        :rules="[v => !!v || 'Role is required']"
                        required
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateFormattedDisplay"
                            label="Birth date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ><vue-fontawesome
                              icon="calendar-alt"
                              slot="prepend"
                              class="mt-1"
                            />{{
                              formatDate(citizen.dateOfBirth)
                            }}</v-text-field
                          >
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="dateFormatted"
                          min="1920-01-01"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-select
                        v-model="citizen.race.label"
                        :items="raceOptions"
                        label="Race"
                        hide-details="auto"
                        :rules="rules"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="citizen.race.other"
                        id="raceOther"
                        name="raceOther"
                        label="Other"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="citizen.ethnicity"
                        :label="ethnicityLabel"
                        id="ethicity"
                        name="ethicity"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeCitizenDialog"> Close </v-btn>
              <v-btn
                elevation="0"
                color="primary"
                class="mr-4"
                :disabled="!valid"
                @click="saveCitizen"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="6" lg="4" class="mt-2">
        <v-text-field
          filled
          rounded
          dense
          single-line
          clearable
          v-model="search"
          label="Filter"
          hide-details
          ><vue-fontawesome
            :icon="['far', 'search']"
            color="grey"
            slot="prepend-inner"
            class="mt-1 mr-1"
        /></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="citizens"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="citizenDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Citizen?</span
                  >
                </v-card-title>
                <v-card-text>
                  This citizen will be permenantly deleted.
                  <div class="text--disabled body-2">{{ citizenId }}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="citizenDialogDelete = false">
                    No
                  </v-btn>
                  <v-btn text @click="deleteCitizenConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.fullName="{ item }">
            <router-link :to="{ path: '/citizen/' + item.id }" class="gray-link"
              ><b>{{ item.firstName + ' ' + item.lastName }}</b></router-link
            >
            <!-- <b>{{ item.fullName }}</b> -->
          </template>
          <template v-slot:item.updatedAt="{ item }">
            {{ formatDate(item.updatedAt) }}
            <!-- by {{ item.updatedBy.fullName }} -->
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link :to="{ path: '/citizen/' + item.id }">
              <v-btn icon small>
                <vue-fontawesome small :icon="['far', 'chevron-right']" />
              </v-btn>
            </router-link>
            <!-- <v-btn icon small @click="editCitizen(item, item.id)">
              <vue-fontawesome
                small
                :icon="['fas', 'pencil-alt']"
                class="mr-1"
              />
            </v-btn> -->
            <v-btn icon small @click="deleteCitizen(item, item.id)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CitizenService from '@/services/CitizenService'
import options from '@/config/valueOptions.json'
import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Citizens',
  components: { Snackbar },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      search: '',
      dateFormatted: '',
      dateFormattedDisplay: '',
      dateMenu: false,
      citizenDialog: false,
      citizenDialogDelete: false,
      citizenIndex: -1,
      citizenId: '',
      citizens: [],
      citizen: {
        id: '',
        gdc: '',
        sid: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: '',
        updatedAt: '',
        gender: '',
        race: {
          label: '',
          other: ''
        },
        ethnicity: false,
        emergencyContact: {
          relation: '',
          firstName: '',
          lastName: '',
          mobilePhone: '',
          email: ''
        }
      },
      citizenDefault: {
        // id: '',
        gdc: '',
        sid: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: '',
        updatedAt: '',
        gender: '',
        race: {
          label: '',
          other: ''
        },
        ethnicity: false,
        emergencyContact: {
          relation: '',
          firstName: '',
          lastName: '',
          mobilePhone: '',
          email: ''
        }
      },
      valid: false,
      rules: [v => !!v || 'This is required'],
      genderOptions: ['Male', 'Female'],
      raceOptions: options.race,
      ethnicityLabel: options.ethnicity,
      headers: [
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'fullName'
        },
        {
          text: 'Gender',
          align: 'start',
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Last Updated',
          align: 'start',
          filterable: true,
          value: 'updatedAt'
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
      // activities: activities
    }
  },
  watch: {
    date(val) {
      if (val) {
        this.dateFormatted = this.formatDate(this.date)
      }
    },
    dateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    citizenDialog(val) {
      // this.isEdit
      val || this.closeCitizenDialog()
    },
    citizenDialogDelete(val) {
      val || this.closeCitizenDelete()
    }
  },
  async created() {
    // const token = this.$store.state.token
    // console.log(token)
    try {
      const citizensResponse = await CitizenService.getAll()
      if (!citizensResponse) {
        return
      }
      this.citizens = citizensResponse.data
    } catch (error) {
      console.log('We had an error: ' + error)
    }
  },
  // created() {
  //   CitizenService.getAll()
  //     .then(response => {
  //       // let token = localStorage.getItem("jwt");
  //       // let decoded = VueJwtDecode.decode(token);
  //       // this.user = decoded;
  //       this.citizens = response.data
  //       // console.log(response.data)
  //     })
  //     .catch(error => {
  //       console.log('We had an error: ' + error)
  //     })
  // },
  mounted() {},
  computed: {
    dobFormatted() {
      const dt = DateTime.now()
      // const str = dt.toISOString()
      // console.log(str)
      return dt.toISOString()
      // return this.citizen.dateOfBirth.substring(0, 10)
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.citizenIndex === -1 ? 'New Citizen' : 'Edit Citizen'
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    }
  },
  methods: {
    reset() {
      this.valid = false
      this.$refs.loginForm.reset()
    },
    resetValidation() {
      this.$refs.loginForm.resetValidation()
    },
    validateForm() {
      this.$refs.loginForm.validate()
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    handleClick(item) {
      //  this broke deletions
      //  @click:row="item => handleClick(item)"
      window.location = 'citizen/' + item.id
      console.log(item.id)
    },
    // formatDate(date) {
    //   if (!date) return null
    //   const dt = DateTime.fromISO(date)
    //   // const dt = DateTime.fromISO(this.citizen.dateOfBirth)
    //   // const str = dt.toISODate()
    //   // console.log('formatDate: ' + str)

    //   return dt.toISODate()
    // },
    age(dateOfBirth) {
      const today = new Date()
      // const dob = this.citizen.dateOfBirth
      const dob = DateTime.fromISO(dateOfBirth)
      // console.log(dob)
      let age = today.getFullYear() - dob.toFormat('yyyy')
      const m = today.getMonth() - dob.toFormat('mm')
      if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age = age - 1
      }
      return age
    },
    dateNow() {
      const dt = DateTime.now()
      return dt.toISOString()
    },
    save(date) {
      this.$refs.dateMenu.save(date)
      this.dateFormattedDisplay = this.formatDate(date)
    },
    editCitizen(item, id) {
      // alert(id)
      this.citizenIndex = this.citizens.indexOf(item)
      // this.dateFormatted = this.formatDate(this.citizen.dateOfBirth)
      // this.dateFormattedDisplay = this.formatDate(this.citizen.dateOfBirth)
      this.citizen = Object.assign({}, item)
      this.citizenId = id
      this.citizenDialog = true
      // console.log(this.citizen)
    },
    deleteCitizen(item, id) {
      // alert(id)
      this.citizenIndex = this.citizens.indexOf(item)
      this.citizen = Object.assign({}, item)
      this.citizenId = id
      this.citizenDialogDelete = true
    },
    async deleteCitizenConfirm() {
      const id = this.citizenId
      try {
        const citizenResult = await CitizenService.delete(id)
        if (!citizenResult) {
          return
        }
        this.snackbar.model = true
        this.snackbar.message = 'Citizen deleted'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
        this.citizens.splice(this.citizenIndex, 1)
        this.closeCitizenDelete()
      } catch (error) {
        this.snackbar.model = true
        this.snackbar.message = error
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(error)
      }
    },
    async saveCitizen() {
      const data = {
        firstName: this.citizen.firstName,
        middleName: this.citizen.middleName,
        lastName: this.citizen.lastName,
        gender: this.citizen.gender,
        sid: this.citizen.sid,
        gdc: this.citizen.gdc,
        dateOfBirth: this.dateFormatted,
        race: {
          label: this.citizen.race.label,
          other: this.citizen.race.other
        },
        ethnicity: this.citizen.ethnicity,
        emergencyContact: {
          relation: this.citizen.emergencyContact.relation,
          firstName: this.citizen.emergencyContact.firstName,
          lastName: this.citizen.emergencyContact.lastName,
          mobilePhone: this.citizen.emergencyContact.mobilePhone,
          email: this.citizen.emergencyContact.email
        },
        createdBy: this.$store.state.user.id
      }
      if (this.citizenIndex > -1) {
        // this.citizen.role.name = this.citizen.roleObject.name
        // alert('update')
        this.citizen.dateOfBirth = this.dateFormatted
        this.citizen.updatedAt = this.dateNow()
        // this.citizen.fullName =
        //   this.citizen.firstName + ' ' + this.citizen.lastName
        Object.assign(this.citizens[this.citizenIndex], this.citizen)
        CitizenService.update(this.citizen.id, data)
          .then(() => {
            this.closeCitizenDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Citizen updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
            // console.log('Citizen edited!' + this.citizen.id)
            // this.submitted = true
          })
          .catch(e => {
            this.snackbar.model = true
            this.snackbar.message = e
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        // const data = {
        //   firstName: this.citizen.firstName,
        //   lastName: this.citizen.lastName,
        //   gender: this.citizen.gender,
        //   sid: this.citizen.sid,
        //   race: {
        //     label: this.citizen.race.label,
        //     other: this.citizen.race.other
        //   }
        // }
        this.saveNewCitizen(data)
      }
    },
    saveNewCitizen(citizenData) {
      CitizenService.create(citizenData)
        .then(response => {
          const newId = response.data.id
          // console.log(newId)
          const newCitizen = Object.assign(this.citizen)
          newCitizen.id = newId
          // const newCitizen = Object.assign({ id: newId }, this.citizen)
          // console.log(newCitizen)
          this.citizens.push(newCitizen)
          this.closeCitizenDialog()
          this.snackbar.model = true
          this.snackbar.message = 'Citizen created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          console.log('Created new citizen successfully! ' + response.data.id)
          // this.submitted = true
        })
        .catch(err => {
          this.snackbar.model = true
          this.snackbar.message = err
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(err)
        })
    },
    closeCitizenDialog() {
      this.citizenDialog = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    },
    closeCitizenDelete() {
      this.citizenDialogDelete = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    }
  }
}
</script>
<style lang="css" scoped>
a.gray-link {
  color: #666666 !important;
}
a.gray-link:hover {
  color: #333333 !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
