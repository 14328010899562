import axios from '../config/api.config'

class CitizenService {
  getAll() {
    return axios.get('/citizens')
  }

  get(id) {
    return axios.get(`/citizens/${id}`)
  }
  getSimple(id) {
    return axios.get(`/citizens/${id}/simple`)
  }
  getUserByAuth0Id(id) {
    return axios.get(`/citizens/auth0/${id}`)
  }
  getByOktaId(id) {
    return axios.get(`/citizens/okta/${id}`)
  }

  create(data) {
    return axios.post('/citizens', data)
  }

  update(id, data) {
    return axios.put(`/citizens/${id}`, data)
  }

  delete(id) {
    return axios.delete(`/citizens/${id}`)
  }

  // deleteAll() {
  //   return axios.delete(`/citizens`)
  // }

  findByTitle(name) {
    return axios.get(`/citizens?name=${name}`)
  }
}

export default new CitizenService()
